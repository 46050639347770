body {
    margin: 0;
    padding-top: 0;
    padding-left: 0;
}


html::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: rgb(32, 30, 30);
}

html::-webkit-scrollbar
{
	width: 12px;
	background-color: rgb(32, 30, 30);
}

html::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

@media screen and (min-width: 800px) {

  #slide-bottom{animation:slide-bottom .8s cubic-bezier(.25,.46,.45,.94) both; animation-delay: .5s;}


  .slide-right{animation:slide-right .8s cubic-bezier(.25,.46,.45,.94) both; animation-delay: .5s;}


  .slide-left{animation:slide-left .8s cubic-bezier(.25,.46,.45,.94) both; animation-delay: .5s;}


  .fade-in-fwd{-webkit-animation:fade-in-fwd .8s cubic-bezier(.39,.575,.565,1.000) both;animation:fade-in-fwd .6s cubic-bezier(.39,.575,.565,1.000) both; animation-delay: .5s;}

  @keyframes slide-right{0%{transform:translateX(-500px);opacity: 0;}100%{transform:translateX(0px); opacity: 1;}}

  @keyframes slide-bottom{0%{transform:translateY(0);opacity: 0;}100%{transform:translateY(100px); opacity: 1;}}

  @keyframes slide-left{0%{transform:translateX(500px);opacity: 0;}100%{transform:translateX(0px); opacity: 1;}}

  @-webkit-keyframes fade-in-fwd{0%{-webkit-transform:translateZ(-80px);transform:translateZ(-80px);opacity:0}100%{-webkit-transform:translateZ(0);transform:translateZ(0);opacity:1}}@keyframes fade-in-fwd{0%{-webkit-transform:translateZ(-80px);transform:translateZ(-80px);opacity:0}100%{-webkit-transform:translateZ(0);transform:translateZ(0);opacity:1}}

}

#parallax{
  /* The image used */
  background-image: url("../../public/img/parallax.jpg");

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}