
body.admin {
    margin: 0;
    padding-top: 4.8rem;
    padding-left: 0.5rem;
}

@media (min-width: 1024px) {
    body.admin {
        padding-left: 16.5rem;
    }
}

.importantVisible {
    visibility: inherit !important;
}

.importantInvisible {
    visibility: hidden !important;
}

html.dark{
    background-color: rgb(17 24 39)
}

.context-menu { 
    position: absolute; 
} 
.menu {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 2px 10px 20px rgba(20, 20, 20, 0.05);
    padding: 10px 0;
}

.menu > li > button {
    font: inherit;
    border: 0;
    padding: 10px 30px 10px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: unset;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    transition: 0.5s linear;
    -webkit-transition: 0.5s linear;
    -moz-transition: 0.5s linear;
    -ms-transition: 0.5s linear;
    -o-transition: 0.5s linear;
}
.menu > li > button:hover {
    background:#f1f3f7;
    color: #4b00ff;
}
.menu > li > button > i {
    padding-right: 10px;
}
.menu > li.trash > button:hover {
    color: red;
}
